import { Icons } from '../../icons';

export enum CreditCardBrands {
	Visa = 'visa',
	MasterCard = 'mastercard',
	AmericanExpress = 'american_express',
	Discover = 'discover'
}

export const creditCardBrandIconMap = new Map<CreditCardBrands, JSX.Element>([
	[CreditCardBrands.Visa, Icons.Visa],
	[CreditCardBrands.MasterCard, Icons.MasterCard],
	[CreditCardBrands.AmericanExpress, Icons.AmericanExpress],
	[CreditCardBrands.Discover, Icons.Discover]
]);
