import { PromoTypes } from '../core/enums/module';
import { BundleProduct } from '../core/models/bundleProduct';
import { Tracking } from '../core/services/tracking/tracking';

export const getSpecialPromotionType = (
	bundleProducts?: BundleProduct[],
	tracking = Tracking.Instance()
): PromoTypes => {
	let promoType = PromoTypes.None;

	const bundleIdPromoTypeMap = new Map<string, PromoTypes>([
		['5acf6afa-5682-11eb-8923-02420a000113', PromoTypes.SigSauerOptic],
		['9cdb83f6-5683-11eb-9675-0242ac120003', PromoTypes.SigSauerAmmo],
		['bbc57d94-5683-11eb-a38c-0242ac12000d', PromoTypes.SigSauerAmmo],
		['d3511568-5683-11eb-8e45-02420a000113', PromoTypes.SigSauerAmmo],
		['f505eab2-5683-11eb-a86e-02420a000113', PromoTypes.SigSauerAmmo],
		['193ab38c-2d0b-11ec-8e58-02420a00014f', PromoTypes.Beretta250],
		['d818d080-7f35-11ee-85be-867eaaf57f93', PromoTypes.Beretta250],
		['ac7bb94a-8692-11eb-af87-0242ac120003', PromoTypes.GiftCard75],
		['bcf22a66-8692-11eb-be6a-02420a0001d1', PromoTypes.GiftCard75],
		['c785672c-8692-11eb-8fac-02420a0001d1', PromoTypes.GiftCard75],
		['d1dc4114-8692-11eb-ad3c-02420a010ec1', PromoTypes.GiftCard75],
		['dbe3e342-8692-11eb-9226-02420a010ec1', PromoTypes.GiftCard75],
		['2a5916ec-1f99-11ec-80aa-0242ac120007', PromoTypes.GiftCard75],
		['af89b9b6-5838-11ec-8be4-02420a00018c', PromoTypes.BrantleyGilbert],
		['84bd7bdc-484a-11ef-8df6-62dc7ede16ef', PromoTypes.PrimaryArmsOptic],
		['624b944e-484a-11ef-9602-d2a84d8dcc5b', PromoTypes.PrimaryArmsOptic],
		['3825e458-3d4b-11ef-b6a6-625daa50282e', PromoTypes.SigSauerGiftCard],
		['202afaf0-3d4b-11ef-ad60-625daa50282e', PromoTypes.SigSauerGiftCard]
	]);
	const tidPromoTypeMap = new Map<string, PromoTypes>([
		['63d96b4da11d4', PromoTypes.PewPew]
	]);

	if (bundleProducts) {
		const bundlePromoType = bundleProducts.reduce(
			(pv, cv) => bundleIdPromoTypeMap.get(cv?.id || '') || pv,
			PromoTypes.None
		);

		promoType = bundlePromoType;
	}

	const tid = tracking.getTid();
	promoType = tidPromoTypeMap.get(tid) || promoType;

	return promoType;
};
