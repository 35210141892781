/* eslint-disable max-lines */
import dayjs from 'dayjs';
import { Environments } from '@delta-defense/client-environments';
import {
	IAnalytics as IFrontendAnalytics,
	Analytics as FrontendAnalytics
} from '@delta-defense/frontend-analytics/analytics/analytics';
import { User as UserTraits } from '@delta-defense/frontend-analytics/models/module';
import {
	GenericEvent,
	OrderCompletedEvent,
	CheckoutStepViewedEvent,
	ITrackingEvent,
	PaymentInfoEnteredEvent
} from '@delta-defense/frontend-analytics/events/module';
import { Version } from '../../../../constants';
import {
	InteractionEvent,
	InternalEvent,
	ErrorEvent,
	TransactionEvent,
	CheckoutEvent,
	CheckoutOptionEvent
} from './eventTypes/module';
import { getProductsToTrack } from '../../../utilities/getProductsToTrack';
import { RouteStepRecord } from '../../../utilities/routeStepRecord';
import { getQueryParameters } from '../../../utilities/getQueryParameter';
import { Transaction } from '../../domain/transaction/transaction';
import { ITransaction } from '../../domain/transaction/ITransaction';
import { Preview, SubscriptionProduct, User } from '../../models/module';
import { ITracking, Tracking } from '../tracking/tracking';
import { Auth, IAuth } from '../auth/auth';
import { QueryParams } from '../../enums/module';
import { EventTypes, DataLayerEvents, Steps, InternalEventNames } from './enums/module';

enum LogType {
	Info,
	Warn,
	Error
}

export interface IAnalytics {
	maintenanceMode: boolean;
	Track: (eventType: EventTypes, event?: object) => void;
	TrackEvent: (event: ITrackingEvent) => void;
	TrackInteraction: (event: InteractionEvent) => void;
	TrackInternal: (event: InternalEvent) => void;
	TrackError: (event: ErrorEvent) => void;
	TrackTransaction: (event: TransactionEvent, previewData?: Preview) => void;
	TrackCheckout: (event: CheckoutEvent, EcommerceEvent?: { new(properties): ITrackingEvent; }) => void;
	TrackCheckoutOption: (event: CheckoutOptionEvent) => void;
	TrackPaymentInfo: (cardBrand?: string) => void;
	Page: (pageName: string, appName?: string) => void;
	Identify: (userData?: User, memberTraits?: UserTraits) => Promise<void>;
	FireDataLayerEvent: (dataLayerEvent: DataLayerEvents, data?: Record<string, any>) => void;
}

export class Analytics implements IAnalytics {
	public maintenanceMode = false;
	private static instance: IAnalytics | null = null;
	public static Instance(
		analyticsFunc = () => FrontendAnalytics.Instance(),
		isProduction = Environments.isProduction,
		auth = Auth.Instance(),
		dataLayerFunc = () => window.dataLayer || [],
		transactionFunc = () => Transaction.Instance(),
		getQueryParametersFunc = getQueryParameters,
		tracking = Tracking.Instance()
	): IAnalytics {
		return (
			this.instance ||
			(this.instance = new Analytics(
				analyticsFunc,
				isProduction,
				auth,
				dataLayerFunc,
				transactionFunc,
				getQueryParametersFunc,
				tracking
			))
		);
	}
	public static Destroy = () => (Analytics.instance = null);

	private constructor(
		private analyticsFunc: () => IFrontendAnalytics,
		private isProductionFunc: () => boolean,
		private auth: IAuth,
		private dataLayerFunc: () => any[],
		private transactionFunc: () => ITransaction,
		private getQueryParametersFunc: typeof getQueryParameters,
		private tracking: ITracking
	) {}

	public Track(eventType: EventTypes, event: object & { scheduled_maintenance?: boolean } = {}): void {
		this.executeAnalyticsCommand((analytics) => {
			const loggedInOrOnStepOne = this.auth.accessToken || RouteStepRecord[location.pathname] === Steps.One;
			const shouldTrackEvent = [EventTypes.Error, EventTypes.InternalEvent].includes(eventType) || loggedInOrOnStepOne;

			if (shouldTrackEvent) {
				event['version'] = Version;

				const eventName: string | undefined = event['event_name'];
				const leadData =
					eventName !== InternalEventNames.FailedToGetTrackingInfoFromCookie
						? this.tracking.getTrackingFieldsConsideringCookie()
						: null;
				if (leadData) {
					event['leadData'] = leadData;
				}

				const genericEvent = new GenericEvent(eventType, event);
				analytics.track(genericEvent);

				this.log(
					eventType === EventTypes.Error ? LogType.Error : LogType.Info,
					`${eventType} - ${JSON.stringify(event)}`,
					event
				);
			}
		});
	}

	public TrackEvent(event: ITrackingEvent): void {
		this.executeAnalyticsCommand((analytics) => {
			analytics.track(event);

			this.log(LogType.Info, `ITrackingEvent - ${event.name} - ${JSON.stringify(event.getData())}`, event.getData());
		});
	}

	public TrackInteraction(event: InteractionEvent): void {
		this.Track(EventTypes.Interaction, event);
	}

	public TrackInternal(event: InternalEvent): void {
		this.Track(EventTypes.InternalEvent, event);
	}

	public TrackError(event: ErrorEvent): void {
		this.Track(EventTypes.Error, event);
	}

	public TrackTransaction(event: TransactionEvent, previewData?: Preview): void {
		const getDiscountsForPurchaseProducts = (products: (SubscriptionProduct | undefined)[]): number => {
			return products.reduce(
				(discount, product) =>
					product
						? discount +
							product.attributes.price / ((100 - product.attributes.discountPercentage) / 100) -
							product.attributes.price
						: discount,
				0
			);
		};

		const discountToTrack = getDiscountsForPurchaseProducts([
			this.transactionFunc().Data.purchaseProducts?.primary,
			this.transactionFunc().Data.purchaseProducts?.spouse
		]);

		const queryParams = this.getQueryParametersFunc();
		const isReferral = [QueryParams.ReferralCode, QueryParams.ReferringMemberName].some((referralCodeQueryParameter) =>
			Array.from(queryParams.keys()).includes(referralCodeQueryParameter)
		);

		const orderCompletedEvent = new OrderCompletedEvent({
			affiliation: event.ecommerce.purchase.actionField.affiliation,
			shipping: 0,
			tax: previewData ? previewData?.attributes.taxAmount / 100 : 0,
			discount: discountToTrack,
			currency: 'USD',
			products: getProductsToTrack(this.transactionFunc()),
			id: event.ecommerce.purchase.actionField.id || '',
			email: this.transactionFunc().Data.email || '',
			is_referral: isReferral,
			checkout_bonus_item_opt_out: !!this.transactionFunc().Data?.bundleProductsDenied,
			ptu_bundle: this.transactionFunc().Data.inPtuFlow
				? this.transactionFunc().Data.purchaseProducts?.primary?.attributes.tier
				: undefined,
			billing_method: this.transactionFunc().Data.paymentMethod?.type
		});

		this.TrackEvent(orderCompletedEvent);
		this.Track(EventTypes.Transaction, event);
	}

	public TrackCheckout(event: CheckoutEvent, EcommerceEvent: { new(properties): ITrackingEvent; } = CheckoutStepViewedEvent): void {
		const checkoutStepViewedEvent = new EcommerceEvent({
			step: event.ecommerce.checkout.actionField.step,
			products: getProductsToTrack(this.transactionFunc())
		});
		this.TrackEvent(checkoutStepViewedEvent);
		this.Track(EventTypes.Checkout, event);
	}

	public TrackCheckoutOption(event: CheckoutOptionEvent): void {
		this.Track(EventTypes.CheckoutOption, event);
	}

	public TrackPaymentInfo(cardBrand?: string): void {
		const paymentInfoEnteredEvent = new PaymentInfoEnteredEvent({
			payment_method: cardBrand,
			products: getProductsToTrack(this.transactionFunc())
		});
		this.TrackEvent(paymentInfoEnteredEvent);
	}

	public Page(pageName: string, appName = 'Checkout'): void {
		this.executeAnalyticsCommand((analytics) => {
			analytics.page(appName, pageName);
			this.log(LogType.Info, `Page: ${appName} | ${pageName}`);
		});
	}

	// eslint-disable-next-line complexity
	public async Identify(userData?: User, memberTraits?: UserTraits): Promise<void> {
		if (!userData && this.auth.accessToken && this.auth.accessTokenObject?.userId) {
			userData = (await this.auth.getUser()).value;
		}

		if (userData?.email) {
			const infoToIdentifyWith = {
				email: userData.email
			};
			if (typeof userData['name'] === 'string') {
				infoToIdentifyWith['firstName'] = userData.name;
			}
			if (typeof userData['surname'] === 'string' && userData.surname != userData.email) {
				infoToIdentifyWith['lastName'] = userData.surname;
			}

			if (memberTraits !== undefined) {
				const addToInfoIfDefinedAsString = (key: string, value: string) => {
					if (typeof memberTraits[key] === 'string') {
						infoToIdentifyWith[key] = value;
					}
				};

				memberTraits.member_start_date = dayjs(memberTraits.member_start_date).toDate();
				memberTraits.membershipExpiration = dayjs(memberTraits.membershipExpiration).toDate();

				addToInfoIfDefinedAsString('membershipLevel', (memberTraits.membershipLevel as string).toLowerCase());
				addToInfoIfDefinedAsString('subscriptionStatus', (memberTraits.subscriptionStatus as string).toLowerCase());
				addToInfoIfDefinedAsString('memberType', (memberTraits.memberType as string).toLowerCase());
			}

			const userId = userData?.id || this.auth.accessTokenObject?.userId || '';

			this.executeAnalyticsCommand((analytics) => {
				analytics.identify(userId, infoToIdentifyWith);
				this.log(LogType.Info, `Identify called: ${userId}`, infoToIdentifyWith);
			});
		}
	}

	public FireDataLayerEvent(dataLayerEvent: DataLayerEvents, data?: Record<string, any>): void {
		const dataLayer = this.dataLayerFunc();
		const event = { event: dataLayerEvent, ...data };

		dataLayer.push(event);
		window.dataLayer = dataLayer;
		this.log(LogType.Info, `dataLayer_event - ${JSON.stringify(event)}`);
	}

	private log(type: LogType, ...message: any): void {
		const consoleMap = new Map<LogType, (params) => void>([
			/* eslint-disable no-console */
			[LogType.Info, (params) => console.log(params)],
			[LogType.Warn, (params) => console.warn(params)],
			[LogType.Error, (params) => console.error(params)]
			/* eslint-enable no-console */
		]);

		if (!this.isProductionFunc()) {
			const consoleFunc = consoleMap.get(type) as (params) => void;
			consoleFunc(message);
		}
	}

	private executeAnalyticsCommand(func: (analytics: IFrontendAnalytics) => void): void {
		const analytics = this.analyticsFunc();

		if (analytics) {
			try {
				func(analytics);
			} catch (error) {
				this.log(LogType.Error, error);
			}
		} else {
			this.log(LogType.Warn, 'analytics not defined');
		}
	}
}
