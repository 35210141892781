import { safelyExecute } from '../../../utilities/safelyExecute';

enum Methods {
	Get = 'GET',
	Post = 'POST',
	Patch = 'PATCH',
	Put = 'PUT',
	Delete = 'DELETE'
}

export type ResponseOf<T> = { body: T; status: number; ok: boolean; errors?: Error[] };
type Fetch = (input: RequestInfo, init?: RequestInit) => Promise<Response>;

const defaultHeaders: Record<string, string> = { 'Content-Type': 'application/json', Accept: 'application/json' };

export interface IRest {
	Get<T>(uri: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>>;
	Post<T>(uri: string, body: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>>;
	Patch<T>(uri: string, body: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>>;
	Put<T>(uri: string, body: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>>;
	Delete<T>(uri: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>>;
}

export class Rest implements IRest {
	private static instance: IRest | null = null;
	public static Instance(fetchRef: Fetch = globalThis.fetch?.bind(globalThis)): IRest {
		return this.instance || (this.instance = new Rest(fetchRef));
	}
	public static Destroy = () => (Rest.instance = null);

	private constructor(private fetchRef: Fetch) {}

	private getRequestCache: Record<string, Promise<ResponseOf<any>>> = {};
	public async Get<T>(uri: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>> {
		this.getRequestCache[uri] =
			this.getRequestCache[uri] ||
			this.getRequestResponse(uri, Methods.Get, undefined, additionalHeaders).then((r) => {
				delete this.getRequestCache[uri];
				return r;
			});
		return await this.getRequestCache[uri];
	}

	public async Post<T>(uri: string, body: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>> {
		return await this.getRequestResponse(uri, Methods.Post, body, additionalHeaders);
	}

	public async Patch<T>(uri: string, body: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>> {
		return await this.getRequestResponse(uri, Methods.Patch, body, additionalHeaders);
	}

	public async Put<T>(uri: string, body: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>> {
		return await this.getRequestResponse(uri, Methods.Put, body, additionalHeaders);
	}

	public async Delete<T>(uri: string, additionalHeaders?: Record<string, string>): Promise<ResponseOf<T>> {
		return await this.getRequestResponse(uri, Methods.Delete, undefined, additionalHeaders);
	}

	private async getRequestResponse<T>(
		uri: string,
		method: Methods,
		body?: string,
		additionalHeaders?: Record<string, string>
	): Promise<ResponseOf<T>> {
		const headers = { ...defaultHeaders, ...additionalHeaders };
		Object.keys(additionalHeaders || {}).forEach((key) => {
			const value = headers[key];
			if (value === null) {
				delete headers[key];
			}
		});

		const response = await this.fetchRef(uri, {
			method,
			headers,
			body
		});

		if (response.ok) {
			return {
				body: response.status != 204 && response.body ? await response.json() : null,
				status: response.status,
				ok: response.ok
			};
		} else {
			safelyExecute(() => {
				(window.deltaApiErrors = window.deltaApiErrors || []).push({
					uri,
					status: response.status,
					statusText: response.statusText
				});
			});
			if (response.status === 401) {
				// handle user logged out
			}
			return { body: {} as T, status: response.status, ok: false };
		}
	}
}
