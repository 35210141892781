/* eslint-disable max-lines */
import type { ITransaction } from './ITransaction';
import type { BundleProduct, SubscriptionProduct } from '../../models/module';
import type { Result } from '../result';
import type { Order } from './order';
import type { Step } from './step';
import { Observable } from 'tsbase/Patterns/Observable/Observable';
import { getSpecialPromotionType } from '../../../utilities/getSpecialPromotionType';
import { getQueryParameter } from '../../../utilities/getQueryParameter';
import { safelyReturn } from '../../../utilities/safelyReturn';
import { Error, Message } from '../../../observables/module';
import {
	Levels,
	Messages,
	PaymentFrequencies,
	ProductTiers,
	PromoTypes,
	QueryParams,
	levelProductTiers,
	UpgradableLevels
} from '../../enums/module';
import { ISubscriptions, Subscriptions } from '../../services/subscriptions/subscriptions';
import { Analytics, IAnalytics } from '../../services/analytics/analytics';
import { EventTypes } from '../../services/analytics/enums/eventTypes';
import { BrowserStorage, ISessionStorage } from '../../services/module';
import { SessionStorageKeys } from '../../services/browserStorage/keys/sessionStorageKeys';

export class Transaction implements ITransaction {
	private static instance: ITransaction | null = null;
	public static Instance(
		steps: Record<string, Step> = {},
		getSpecialPromotionTypeFunction = getSpecialPromotionType,
		mainWindow = globalThis.window,
		getQueryParameterFunc = getQueryParameter,
		subscriptions = Subscriptions.Instance(),
		analytics = Analytics.Instance(),
		session = BrowserStorage.SessionInstance()
	): ITransaction {
		return (
			this.instance ||
			(this.instance = new Transaction(
				steps,
				getSpecialPromotionTypeFunction,
				mainWindow,
				getQueryParameterFunc,
				subscriptions,
				analytics,
				session
			))
		);
	}
	public static Destroy = () => (Transaction.instance = null);

	get useDefaultBundle(): boolean {
		return !!this.Data?.useDefaultBundle;
	}

	set useDefaultBundle(v: boolean) {
		this.Data.useDefaultBundle = v;
	}

	public get PrimarySub() {
		return this.Data.customerSubscriptions?.find(s =>
			s.attributes.status === 'active' &&
			!['spouse', 'group'].some(t => s.attributes.type.includes(t)));
	}
	public get SpouseSub() {
		return this.Data.customerSubscriptions?.find(s =>
			s.attributes.status === 'active' &&
			s.attributes.type.includes('spouse'));
	}
	public get GroupSub() {
		return this.Data.customerSubscriptions?.find(s =>
			s.attributes.status === 'active' &&
			s.attributes.type.includes('group'));
	}
	public get UpgradingFromLevel() {
		const currentLevel = this.PrimarySub?.attributes.level;
		if (currentLevel && Object.values(UpgradableLevels).includes(currentLevel as UpgradableLevels)) {
			return currentLevel as UpgradableLevels;
		}
		return '';
	}
	public get SpouseOnlyPurchase() {
		const productId = this.getQueryParameterFunc(QueryParams.ProductId);
		const matchingSpouseProductOfSameLevel = this.Data.productData?.find(p =>
			p.id === productId &&
			p.attributes.tags.find(t =>
				t.urlSafe.includes('spouse')) &&
			p.attributes.tags.find(
				t => t.urlSafe.includes(this.UpgradingFromLevel.toLowerCase()))
		);

		return !!(
			!this.SpouseSub &&
			productId &&
			this.PrimarySub &&
			matchingSpouseProductOfSameLevel
		);
	}
	public get SubscriptionProductForParam(): SubscriptionProduct | undefined {
		const productId = this.getQueryParameterFunc(QueryParams.ProductId);
		return this.Data.productData?.find(p => p.id === productId) ||
			this.Data.productData?.find(p => p.attributes.externalId === productId) ||
			this.Data.productData?.find(p => p.attributes.externalServicesId === productId);
	}
	public Order: Order = { subscriptionProducts: [], bundleProducts: [] };
	public Data: ITransaction['Data'] = {
		email: '',
		useDefaultBundle: false
	};
	public Error = Error;
	public Message = Message;
	public Updates = new Observable<boolean>();

	private constructor(
		public Steps: Record<string, Step>,
		private getSpecialPromotionTypeFunction: (bundleProducts?: BundleProduct[]) => PromoTypes,
		private mainWindow: Window,
		private getQueryParameterFunc: typeof getQueryParameter,
		private subscriptions: ISubscriptions,
		private analytics: IAnalytics,
		private mainSessionStorage: ISessionStorage
	) {
		this.Updates.Publish(true);
	}

	public GetCurrentStepKey(): string {
		const stepKeys = Object.keys(this.Steps);
		return stepKeys.find((key) => !this.Steps[key].Completed) || stepKeys[stepKeys.length - 1];
	}

	public GetCurrentStep(): Step {
		return this.Steps[this.GetCurrentStepKey()];
	}

	public SetCurrentStep(stepKey: string, resetFields = false, disablePersistence = false) {
		const stepKeys = Object.keys(this.Steps);
		const index = stepKeys.indexOf(stepKey);

		stepKeys.forEach((key, i) => {
			const step = this.Steps[key];
			if (i < index) {
				step.Completed = true;
			} else {
				step.Completed = false;
				if (resetFields) {
					Object.values(step.Fields).forEach((field) => {
						field.Clean = true;
						field.SetValue('');
					});
				}
			}
		});

		this.Updates.Publish(true);
		!disablePersistence ? this.persistAtCurrentRoute() : null;
	}

	public SetPurchaseProducts(
		frequency: PaymentFrequencies,
		addSpouse = !!this.Data.purchaseProducts?.spouse
	): void {
		const spouseStep = this.Steps.spouseAddOn || this.Steps.elegant;
		const userFilledSpouseFields = safelyReturn(() => [
			'addSpouse',
			'spouseFirstName',
			'spouseLastName'
		].every(k => !!spouseStep.Fields[k].GetValue()), false);

		addSpouse = addSpouse || !!this.SpouseSub || this.SpouseOnlyPurchase || userFilledSpouseFields;

		const primary = this.SpouseOnlyPurchase ?
			undefined :
			this.Data.productData?.find(p =>
				!p.attributes.tier.toLowerCase().includes('spouse') &&
				p.attributes.paymentFrequency === frequency
			);
		const spouse = addSpouse ?
			this.Data.productData?.find(p =>
				p.attributes.tier.toLowerCase().includes('spouse') &&
				p.attributes.paymentFrequency === frequency
			) :
			undefined;

		this.Data.purchaseProducts = {
			primary: primary,
			spouse: spouse
		};
	}

	public IsMembershipPurchase(): boolean {
		const purchaseProducts = this.Data.purchaseProducts;
		return !!(
			purchaseProducts?.spouse ||
			(purchaseProducts?.primary && purchaseProducts.primary.attributes.tier !== ProductTiers.CCM)
		);
	}

	public IsCCMPurchase(): boolean {
		const purchaseProducts = this.Data.purchaseProducts;

		if (purchaseProducts?.primary) {
			return !!(purchaseProducts.primary.attributes.tier === ProductTiers.CCM);
		} else {
			return !!this.Data.productData?.find(p => p.attributes.tier === ProductTiers.CCM);
		}
	}

	public IncludesPromo(promoType: PromoTypes): boolean {
		return this.getSpecialPromotionTypeFunction(this.Data.bundleProducts) === promoType;
	}

	public async SetProductDataById(productId: string | null): Promise<Result<SubscriptionProduct[]>> {
		if (!productId || this.Data.productData?.find(p =>
			p.id === productId ||
			p.attributes?.externalServicesId === productId
		)) {
			return {
				wasSuccessful: true,
				value: this.Data.productData
			};
		}

		this.Message.Publish(Messages.LoadingProductsForLevel);
		const productsResult = await this.subscriptions.getProductsById(productId);
		this.Message.Publish(Messages.Empty);

		if (productsResult.wasSuccessful) {
			this.Data.productData = productsResult.value;
		}

		return productsResult;
	}

	public IsSameLevelMonthlyToAnnualUpgrade = (): boolean => {
		const prospectiveProduct = this.Data.purchaseProducts?.primary || this.SubscriptionProductForParam;
		const currentLevel = this.PrimarySub?.attributes.level;

		if (!currentLevel || !prospectiveProduct) {
			return false;
		}

		const currentTier = levelProductTiers[(currentLevel.toLowerCase()) as Levels] as ProductTiers | undefined;
		const prospectiveLevelIsSameAsCurrent = currentTier?.toLowerCase() === prospectiveProduct.attributes.tier.toLowerCase();
		const prospectiveLevelMovesFrequencyToAnnual = this.PrimarySub?.attributes.paymentFrequency === PaymentFrequencies.Monthly &&
			prospectiveProduct.attributes.paymentFrequency === PaymentFrequencies.Annual;

		return prospectiveLevelIsSameAsCurrent && prospectiveLevelMovesFrequencyToAnnual;
	};

	private persistAtCurrentRoute(): void {
		const currentRoute = `${this.mainWindow.location.pathname}${this.mainWindow.location.search}`;
		const transactionData = JSON.stringify(this.Data);

		this.mainSessionStorage.setStringValue(SessionStorageKeys.TransactionData, transactionData);
		this.mainSessionStorage.setItem(SessionStorageKeys.LastKnownRoute, currentRoute);

		this.analytics.Track(EventTypes.StateOfCart, {
			[SessionStorageKeys.TransactionData]: transactionData,
			[SessionStorageKeys.LastKnownRoute]: currentRoute
		});
	}
}
