/* eslint-disable max-lines */
import React from 'react';
import { SupportPhoneLink } from '../../../components/SupportPhoneLink/SupportPhoneLink';
import { Field, FieldValidation, ITransaction, Step, Transaction } from '../../../core/domain/module';
import { PromoTypes } from '../../../core/enums/module';
import { FieldTypes } from '../../fieldTypes';
import { conditional } from '../../fieldValidations/conditional';
import { required } from '../../fieldValidations/required';
import { PaymentFields } from './paymentFields';
import { Analytics, FormFields, InteractionEventNames, Locations, Steps, SubLocations } from '../../../core/services/analytics/module';
import styles from '../../../pages/signup/payment.module.scss';
import { Zuora } from '../../../core/services/module';
import { DefaultSteps } from '../../default';
import { PaymentStepResultKeys } from './paymentStepResultKeys';

export const requiredForPromo = (promoType: PromoTypes, transactionFunc: () => ITransaction): FieldValidation =>
	conditional(() => transactionFunc().IncludesPromo(promoType), required);

export const deltaTermsValidationMessage = "You must agree to Delta Defense's terms and conditions to continue.";
export const promoTermsValidationMessage = 'You must agree to the promotional terms and conditions to continue.';
const oneDollarVerificationChargeCopy =
	// eslint-disable-next-line max-len
	'After purchase, you may receive a $1 charge on your credit or debit card. This is a verification charge to ensure funds. This $1 charge will pend on account and drop off per bank waiting period.';

export const paymentFields = (transactionFunc: () => ITransaction): Record<PaymentFields, Field> => ({
	[PaymentFields.MembershipTerms]: new Field(
		PaymentFields.MembershipTerms,
		(
			<p className={styles.textSmall}>
				I represent and acknowledge that I have read, fully understand, and agree to be bound by USCCA’s{' '}
				<a
					onClick={() => {
						Analytics.Instance().TrackInteraction({
							event_name: InteractionEventNames.LinkClick,
							events: {
								click_text: 'Membership Agreement Terms',
								form_field: FormFields.AddPaymentInfo,
								location: Locations.Content,
								sub_location: SubLocations.Step4
							},
							step: Steps.Four
						});
					}}
					href="https://www.usccamemberterms.com/"
					target="_blank"
				>
					Membership Agreement Terms
				</a>{' '}
				and the warranties contained therein. I certify and warrant that I am at least 18 years of age and that all of the information
				contained in my Application is true and correct. I understand that I can cancel at any time by simply calling Delta Defense at{' '}
				<SupportPhoneLink location={Locations.Content} />.
				<p className={styles.activationNotice}>
					By activating my membership today, I authorize Delta Defense to automatically renew my membership charged to the method of payment
					provided.
				</p>
				<p>{oneDollarVerificationChargeCopy}</p>
			</p>
		),
		FieldTypes.Checkbox,
		'',
		[conditional(() => transactionFunc().IsMembershipPurchase(), required)]
	),
	[PaymentFields.CCMTerms]: new Field(
		PaymentFields.CCMTerms,
		(
			<>
				<p className={styles.textSmall}>
					I certify and warrant that I am at least 18 years of age. I understand that I can cancel at any time by simply calling Delta
					Defense at <SupportPhoneLink />. By activating my subscription today, I authorize Delta Defense to automatically renew my
					subscription charged to the method of payment provided.
				</p>
				<p>{oneDollarVerificationChargeCopy}</p>
			</>
		),
		FieldTypes.Checkbox,
		'',
		[conditional(() => transactionFunc().IsCCMPurchase(), required)]
	),
	[PaymentFields.SigSauerAmmoTerms]: new Field(
		PaymentFields.SigSauerAmmoTerms,
		(
			<p className={styles.textSmall}>
				I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $150
				for the promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA Bulletproof Guarantee;
				or 2) I cannot show proof of purchase of a Sig Sauer firearm that was eligible for this offer.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.SigSauerAmmo, transactionFunc)]
	),
	[PaymentFields.SigSauerOpticTerms]: new Field(
		PaymentFields.SigSauerOpticTerms,
		(
			<p className={styles.textSmall}>
				I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $219.99 for the
				promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA Bulletproof Guarantee; or 2) I
				cannot show proof of purchase of a Sig Sauer firearm that was eligible for this offer.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.SigSauerOptic, transactionFunc)]
	),
	[PaymentFields.Beretta250Terms]: new Field(
		PaymentFields.Beretta250Terms,
		(
			<p className={styles.textSmall}>
				I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $250
				for the promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA Bulletproof Guarantee;
				or 2) I cannot show proof of purchase of a Beretta firearm that was eligible for this offer.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.Beretta250, transactionFunc)]
	),
	[PaymentFields.GiftCardGeneralTerms]: new Field(
		PaymentFields.GiftCardGeneralTerms,
		(
			<p className={styles.textSmall}>
				I agree that if I cancel my membership, the USCCA Bulletproof Guarantee will reimburse me the full amount I have paid towards
				my USCCA membership, but that upon cancellation I may be responsible to pay for the promotional item associated with this offer.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.GiftCard75, transactionFunc)]
	),
	[PaymentFields.GiftCard75Terms]: new Field(
		PaymentFields.GiftCard75Terms,
		(
			<p className={styles.textSmall}>
				I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the payment of $75.00 for the
				promotional item in the event that I cancel my USCCA Membership pursuant to the USCCA Bulletproof Guarantee.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.GiftCard75, transactionFunc)]
	),
	[PaymentFields.BrantleyGilbertTerms]: new Field(
		PaymentFields.BrantleyGilbertTerms,
		(
			<p className={styles.textSmall}>
				I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $89.99 (BG
				Selects Premium box) in the event that: I cancel my USCCA Membership pursuant to the USCCA Bulletproof Guarantee.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.BrantleyGilbert, transactionFunc)]
	),
	[PaymentFields.UseCardOnFile]: new Field(PaymentFields.UseCardOnFile, '', FieldTypes.UseCardOnFile, '', []),
	[PaymentFields.PewPewTerms]: new Field(
		PaymentFields.PewPewTerms,
		(
			<p className={styles.textSmall}>
				Please note that your information will be shared with Pew Pew Tactical for the purposes of redeeming your free gift.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.PewPew, transactionFunc)]
	),
	[PaymentFields.PrimaryArmsOptic]: new Field(
		PaymentFields.PrimaryArmsOptic,
		(
			<p className={styles.textSmall}>
				I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $149.99
				for the promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA Bulletproof Guarantee; or
				2) I cannot show proof of purchase of a Primary Arms firearm that was eligible for this offer.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.PrimaryArmsOptic, transactionFunc)]
	),
	[PaymentFields.SigSauerGiftCard]: new Field(
		PaymentFields.SigSauerGiftCard,
		(
			<p className={styles.textSmall}>
				I authorize Delta Defense LLC to charge the credit or debit card on file and impose and collect the MSRP payment of $150
				for the promotional item in the event that: 1) I cancel my USCCA Membership pursuant to the USCCA Bulletproof Guarantee;
				or 2) I cannot show proof of purchase of a Sig Sauer firearm that was eligible for this offer.
			</p>
		),
		FieldTypes.Checkbox,
		promoTermsValidationMessage,
		[requiredForPromo(PromoTypes.SigSauerGiftCard, transactionFunc)]
	)
});

export const Payment = (transactionFunc: () => ITransaction = () => Transaction.Instance(), zuora = Zuora.Instance()) =>
	new Step(paymentFields(transactionFunc), async () => {
		const useCardOnFile = transactionFunc().Steps[DefaultSteps.Payment].Fields[PaymentFields.UseCardOnFile].GetValue() === 'yes';
		const usingApplePay = !!transactionFunc().Data.usingApplePay;
		const clientPaymentValidationSuccessful = !(useCardOnFile || usingApplePay) && await zuora.Validate();

		return {
			wasSuccessful: true,
			[PaymentStepResultKeys.ClientPaymentValidationSuccessful]: clientPaymentValidationSuccessful,
			[PaymentStepResultKeys.UseCardOnFile]: useCardOnFile,
			[PaymentStepResultKeys.UsingApplePay]: usingApplePay
		};
	});
