import { FeatureFlagService, LaunchDarklyRepository } from '@delta-defense/feature-flag-service';
import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import { Analytics } from '../core/services/analytics/analytics';
import { InternalEventNames } from '../core/services/analytics/enums/internalEventNames';
import { getQueryParameters } from './getQueryParameter';
import { BrowserStorage } from '../core/services/module';
import { SessionStorageKeys } from '../core/services/browserStorage/keys/sessionStorageKeys';

export const initializeFeatureFlagService = (
	mainWindow = globalThis.window,
	analytics = Analytics.Instance(),
	storage = BrowserStorage.SessionInstance()
): IFeatureFlagRepository => {
	const getCustomAttributes = (): Record<string, string> => {
		const params = getQueryParameters(mainWindow.location.search);
		const paramsObject = {};
		Array.from(params).forEach((p) => (paramsObject[p[0]] = p[1]));
		const newMemberPricePaidSet = !!storage.getItem(SessionStorageKeys.NewMemberPricePaid);

		return {
			...paramsObject,
			newMember: newMemberPricePaidSet.toString()
		};
	};

	const timeoutCallback = () => {
		analytics.TrackInternal({ event_name: InternalEventNames.FeatureFlagsTimeout });
	};
	const flagsLoadedCallback = (flags: any) => {
		analytics.TrackInternal({ event_name: InternalEventNames.FeatureFlagsLoaded, data: flags });
	};
	const thirdPartyRepository = new LaunchDarklyRepository(getCustomAttributes, timeoutCallback, flagsLoadedCallback);
	const featureFlagsChecked: string[] = [];
	return FeatureFlagService.Instance(thirdPartyRepository, (flag, value) => {
		if (!value && value !== 0) {
			value = false;
		}
		if (!featureFlagsChecked.includes(flag)) {
			analytics.TrackInternal({
				event_name: InternalEventNames.FeatureFlagChecked,
				data: { flag, value: value as any }
			});
			featureFlagsChecked.push(flag);
		}
	});
};
