import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { ITransaction, Transaction } from '../core/domain/module';
import { QueryParams } from '../core/enums/queryParams';
import { getQueryParameter } from './getQueryParameter';
import { Routes } from '../core/enums/routes';
import { Analytics, IAnalytics, Steps } from '../core/services/analytics/module';
import { getCheckoutType } from './getCheckoutType';
import { getProductsForAnalytics } from './getProductsForAnalytics';
import { RouteStepRecord } from './routeStepRecord';

export const getCheckoutEventForStep = (step: Steps, products: any[] = [], transactionData = Transaction.Instance().Data) => ({
	ecommerce: {
		checkoutType: getCheckoutType(transactionData.purchaseProducts, transactionData.customerSubscriptions),
		checkout: {
			actionField: {
				step: step
			},
			products: products
		}
	}
});

const RouteEventFuncRecord: Record<
	Routes,
	(
		analytics: IAnalytics,
		features: IFeatureFlagRepository,
		transaction: ITransaction,
		getQueryParameterFunc: (param: QueryParams, locationSearch?: string) => string | null
	) => void
> = {
	[Routes.Root]: () => null,
	[Routes.Elegant]: () => null,
	[Routes.Email]: (analytics) => {
		analytics.Page('Enter Email');
	},
	[Routes.Existing]: (analytics) => {
		analytics.Page('Existing Member');
	},
	[Routes.SignupVerification]: (analytics) => {
		analytics.Page('Existing Account - Verification Code');
		analytics.TrackCheckout(getCheckoutEventForStep(RouteStepRecord[Routes.SignupVerification]));
	},
	[Routes.SignupPassword]: (analytics) => {
		analytics.Page('Existing Account - Enter Password');
	},
	[Routes.SignupNextRegistration]: (analytics) => {
		analytics.Page('Next Step - Finish Account Setup Screen');
		analytics.TrackCheckout(getCheckoutEventForStep(RouteStepRecord[Routes.SignupNextRegistration]));
	},
	[Routes.SignupRegistration]: (analytics) => {
		analytics.Page('Set Password Screen');
	},
	[Routes.SignupLevel]: (analytics) => {
		analytics.Page('Next Step - Select Membership Level');
		analytics.TrackCheckout(getCheckoutEventForStep(RouteStepRecord[Routes.SignupLevel]));
	},
	[Routes.SignupChart]: (analytics) => {
		analytics.Page('Membership Chart');
	},
	[Routes.SignupFrequency]: (analytics, _features, transaction, getQueryParameterFunc) => {
		analytics.Page('Payment Frequency');
		if (getQueryParameterFunc(QueryParams.ProductId)) {
			analytics.TrackCheckout(
				getCheckoutEventForStep(
					Steps.Three,
					getProductsForAnalytics(transaction.Data.purchaseProducts, transaction.Data.bundleProducts)
				)
			);
		}
	},
	[Routes.SignupSpouse]: (analytics) => {
		analytics.Page('Spouse Add on');
	},
	[Routes.SignupNextShipping]: (analytics, _features, transaction) => {
		analytics.Page('Next Step - Shipping Address');
		analytics.TrackCheckout(
			getCheckoutEventForStep(
				RouteStepRecord[Routes.SignupNextShipping],
				getProductsForAnalytics(transaction.Data.purchaseProducts, transaction.Data.bundleProducts)
			)
		);
	},
	[Routes.SignupShipping]: (analytics) => {
		analytics.Page('Shipping Address');
	},
	[Routes.SignupNextPayment]: (analytics, _features, transaction) => {
		analytics.Page('Next Step - Payment');
		analytics.TrackCheckout(
			getCheckoutEventForStep(
				RouteStepRecord[Routes.SignupNextPayment],
				getProductsForAnalytics(transaction.Data.purchaseProducts, transaction.Data.bundleProducts)
			)
		);
	},
	[Routes.SignupPayment]: (analytics) => {
		analytics.Page('Payment Form');
	},
	[Routes.SignupSummary]: (analytics) => {
		analytics.Page('Order Summary');
	},
	[Routes.PostTransactionUpdatePassword]: (analytics) => {
		analytics.Page('Post Transaction Update Password');
	},
	[Routes.PostTransactionReferral]: (analytics) => {
		analytics.Page('Refer A Friend');
	},
	[Routes.SignupWelcome]: () => null,
	[Routes.SingleClickPurchase]: (analytics) => {
		analytics.Page('Single Click Purchase');
	},
	[Routes.ProductPTU]: () => null,
	[Routes.SSO]: () => null,
	[Routes.CartAbandonment]: (analytics) => {
		analytics.Page('Cart Abandonment');
	},
	[Routes.Error]: (analytics) => {
		analytics.Page('Error');
	}
};

export const trackRouteChangeEvents = (
	route: Routes,
	analytics = Analytics.Instance(),
	features = FeatureFlagService.Instance(),
	transaction = Transaction.Instance(),
	getQueryParameterFunc = getQueryParameter
): void => {
	RouteEventFuncRecord[route]?.(analytics, features, transaction, getQueryParameterFunc);
};
