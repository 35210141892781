/* eslint-disable max-lines */
import React from 'react';
import { DismissibleErrors } from '../../core/enums/dismissibleErrors';
import { SupportPhoneLink } from '../SupportPhoneLink/SupportPhoneLink';
import { AMACSupportLink } from '../AMACSupportLink/amacSupportLink';

export type DismissibleErrorObject = {
	heading: string;
	body: JSX.Element;
};

const genericCheckInputMessage = (
	<p>
		Please check the information entered and try again. If you need further assistance, call us at <SupportPhoneLink /> to complete your
		purchase.
	</p>
);

export const DismissibleErrorMap = new Map<DismissibleErrors, DismissibleErrorObject>([
	[
		DismissibleErrors.CreditCardError,
		{
			heading: 'Payment Method Error',
			body: (
				<>
					<p>Error Adding Payment Method</p>
					{genericCheckInputMessage}
				</>
			)
		}
	],
	[
		DismissibleErrors.UserSessionExpired,
		{
			heading: 'Session Expired',
			body: (
				<>
					<p>
						Your session has expired. Please log in and try again. If you need further assistance, call us at <SupportPhoneLink /> to
						complete your purchase.
					</p>
				</>
			)
		}
	],
	[
		DismissibleErrors.ErrorCreatingAccount,
		{
			heading: 'Error Creating Account',
			body: (
				<p>
					There was a technical error while setting up your account. Please try again or call us at <SupportPhoneLink /> to complete your
					purchase.
				</p>
			)
		}
	],
	[
		DismissibleErrors.ExpiredVerificationCode,
		{
			heading: 'Expired Verification Code',
			body: (
				<p>
					The code you've entered has expired. We will send you a new code. Please try again or call us at <SupportPhoneLink /> to complete
					your purchase.
				</p>
			)
		}
	],
	[
		DismissibleErrors.InvalidVerificationCode,
		{
			heading: 'Invalid Verification Code',
			body: (
				<p>
					Please check the code entered and try again. If you need further assistance, call us at <SupportPhoneLink /> to complete your
					purchase.
				</p>
			)
		}
	],
	[
		DismissibleErrors.ErrorSubmittingVerificationCode,
		{
			heading: 'Error Submitting Verification Code',
			body: (
				<p>
					Something went wrong when submitting the verification code. We will send you a new code. Please try again or call us at{' '}
					<SupportPhoneLink /> to complete your purchase.
				</p>
			)
		}
	],
	[
		DismissibleErrors.EmailRequestedWithNoAddressSpecified,
		{
			heading: 'Email Address Missing',
			body: (
				<p>
					Please enter your email address to continue. If you need further assistance, please call us at <SupportPhoneLink /> to complete
					your purchase.
				</p>
			)
		}
	],
	[
		DismissibleErrors.ErrorSendingVerificationEmail,
		{
			heading: 'Error Sending Verification Email',
			body: (
				<p>
					There was a technical error sending your verification email. Please use your USCCA password to login. If you need further
					assistance, call us at <SupportPhoneLink /> to complete your purchase.
				</p>
			)
		}
	],
	[
		DismissibleErrors.ErrorLoadingCartPreview,
		{
			heading: 'Error Loading Cart Preview',
			body: (
				<p>
					There was a technical error loading the preview of your cart. Refreshing the page may correct the issue. If the problem persists
					or you need further assistance, please call us at <SupportPhoneLink /> to complete your purchase.
				</p>
			)
		}
	],
	[
		DismissibleErrors.ErrorUpdatingUser,
		{
			heading: 'Error Saving Customer Information',
			body: genericCheckInputMessage
		}
	],
	[
		DismissibleErrors.ErrorUpdatingCustomer,
		{
			heading: 'Error Updating Address',
			body: genericCheckInputMessage
		}
	],
	[
		DismissibleErrors.ErrorCreatingCustomer,
		{
			heading: 'Error Saving Address',
			body: genericCheckInputMessage
		}
	],
	[
		DismissibleErrors.ErrorLoggingIn,
		{
			heading: 'Error Logging In',
			body: genericCheckInputMessage
		}
	],
	[
		DismissibleErrors.ErrorUpdatingPassword,
		{
			heading: 'Error Updating Password',
			body: genericCheckInputMessage
		}
	],
	[
		DismissibleErrors.ErrorProcessingOrder,
		{
			heading: 'Error Processing Order',
			body: genericCheckInputMessage
		}
	],
	[
		DismissibleErrors.InvalidMembershipLocation,
		{
			heading: 'Invalid Membership Location',
			body: (
				<>
					<p>
						USCCA Memberships are currently unavailable in the states of New Jersey, New York and Washington, as well as outside of the
						United States. We regret to inform you that we are unable to process your membership request, but are working diligently to
						develop membership solutions for these states.
					</p>
					<p>
						Until then, we hope that you find value in the{' '}
						<a href="https://www.usconcealedcarry.com/resources">education and training resources</a> provided by the USCCA that are built
						to help responsible Americans like you become a stronger and more confident protector.
					</p>
				</>
			)
		}
	],
	[
		DismissibleErrors.UserTryingToDowngrade,
		{
			heading: "Can't Upgrade to Equal or Lower Level",
			body: (
				<>
					<p>Thank you for helping us save lives!</p>
				</>
			)
		}
	],
	[
		DismissibleErrors.ErrorPurchasingPtu,
		{
			heading: 'Purchase Failed',
			body: (
				<>
					<p>
						Something went wrong when attempting to purchase your bonus items. However, your membership purchase was successful. You can
						dismiss this message to view more details about your membership.
					</p>
					<p>
						If you would still like to purchase the bonus items, please call us at <SupportPhoneLink />, and we would be happy to assist
						you.
					</p>
				</>
			)
		}
	],
	[
		DismissibleErrors.AMACExtensionAlreadyRedeemed,
		{
			heading: 'AMAC Account Already Extended',
			body: (
				<>
					<p>Congratulations. It looks like you have already redeemed your AMAC extension.</p>
					<p>
						Thank you for being a member of AMAC and the USCCA. If you have questions, regarding this extension, feel free to reach out to
						AMAC support at <AMACSupportLink />
					</p>
				</>
			)
		}
	],
	[
		DismissibleErrors.AMACExtensionEncounteredError,
		{
			heading: 'AMAC Account Extension Encountered an Error.',
			body: (
				<>
					<p>Sorry, it seems an error occurred while trying to redeem your AMAC extension.</p>
					<p>Please contact AMAC support at <AMACSupportLink /></p>
				</>
			)
		}
	],
	[
		DismissibleErrors.UnauthedPaymentMethodAttempt,
		{
			heading: 'Please Enter Email',
			body: (
				<>
					<p>You must enter an email address before you are able to add a payment method.</p>
					{genericCheckInputMessage}
				</>
			)
		}
	]
]);
